import React, { useState } from "react";
import { useAuthContext } from "../../contexts/authContext";
import PageLayout from "../../components/PageLayout";
import PageContent from "../../components/PageContent";
import PageBoundary from "../../components/PageBoundary";
import LoadingStatus from "../../components/LoadingStatus";
import BannerComponent from "../../components/Banner";
import clsx from "clsx";
import EquityInsightsPage from "../../components/EquityInsightsPage";
import MacroInsightsPage from "../../components/MacroInsightsPage";
import { useInsightsContext } from "../../contexts/insightsContext";

const InsightsPage: React.FC = () => {
  const { accessToken, whoami } = useAuthContext();
  const canViewMacro = (whoami as any)?.can_view_macro;

  // tab
  const tabs = ["Macro", "Equities"];
  const { activeTab, setActiveTab } = useInsightsContext();

  const tempSEOData = {
    title: "BAM Applied Insights",
    description: "Balyasny's AI-Enabled Research Platform for Core Investors",
  };

  const tempBannerData = {
    title1: "Access AI-Enabled research from BAM's proprietary catalogue",
    description: `Welcome to Balyasny's new AI-enabled research platform created exclusively for our Core Investors. This tool generates robust summaries from our research library. BAM is breaking ground as the first multi-strategy firm to offer its investors such a platform, representing our commitment to transparency and partnership.`,
  };

  return (
    <PageLayout seo={tempSEOData}>
      <PageContent className="flex flex-col">
        {!accessToken ? (
          <LoadingStatus loadingType="page_error" />
        ) : (
          <div className="flex flex-col">
            <BannerComponent
              title1={tempBannerData.title1}
              description={tempBannerData.description}
            />
            {canViewMacro ? (
              <>
                <PageBoundary className="w-full">
                  <div className="pt-6 mb-3 border-b border-bam-shade-gray">
                    <nav
                      aria-label="Tabs"
                      className="flex -mb-px space-x-8 sm:space-x-12"
                    >
                      {tabs.map((tab) => (
                        <button
                          key={tab}
                          onClick={() =>
                            setActiveTab(tab as "Macro" | "Equities")
                          }
                          className={clsx(
                            "font-semibold text-base sm:text-lg border-b-2 py-1.5",
                            activeTab === tab
                              ? "text-bam-orange border-bam-orange"
                              : "text-gray-500 border-transparent hover:border-b-bam-medium-gray hover:text-bam-gray-600",
                          )}
                        >
                          {tab}
                        </button>
                      ))}
                    </nav>
                  </div>
                </PageBoundary>
                {activeTab === "Macro" && <MacroInsightsPage />}
                {activeTab === "Equities" && <EquityInsightsPage />}
              </>
            ) : (
              <EquityInsightsPage />
            )}
          </div>
        )}
      </PageContent>
    </PageLayout>
  );
};

export default InsightsPage;
