import React, { useEffect } from "react";
import { useAuthContext } from "../../contexts/authContext";
import LoadingStatus from "../../components/LoadingStatus";
import PageBoundary from "../PageBoundary";
import ReportList from "../../components/ReportList";
import useNorthstar from "../../hooks/northstarHook";
import MacroReportFilter from "../MacroReportFilter";
import {
  CMSAPIDataCollection,
  CMSAPIDataItem,
  MacroLatestDocumentsAttributes,
  MacroRegionAttributes,
  MacroTopicAttributes,
} from "../../types/cms";
import { useMacroDocuments } from "../../hooks/macroDocumentsHook";

const MacroInsightsPage = () => {
  const { accessToken, clearIdentity } = useAuthContext();

  const {
    isLoadingInitial,
    hasMore,
    loadMoreDocuments,
    isLoadingMore,
    selectedRegions,
    setSelectedRegionsWithReset,
    selectedTopics,
    setSelectedTopicsWithReset,
    macroDocuments,
  } = useMacroDocuments(accessToken, true);

  const {
    data: latestMacroDocuments,
    isLoading: isLoadingLatestMacroDocuments,
    error: errorLoadingLatestMacroDocuments,
  } = useNorthstar<MacroLatestDocumentsAttributes>(
    `/api/latest-macro-documents`,
    accessToken,
    {
      method: "get",
    },
  );

  const {
    data: macroRegionsData,
    isLoading: isLoadingMacroRegions,
    error: errorLoadingRegions,
  } = useNorthstar<CMSAPIDataCollection<CMSAPIDataItem<MacroRegionAttributes>>>(
    `/api/macro-regions`,
    accessToken,
    {
      method: "get",
    },
    {
      fields: ["region", "order"],
      sort: { order: "asc" },
    },
  );
  const macroRegions =
    macroRegionsData?.data?.map((region) => region.attributes) || [];

  const {
    data: macroTopicsData,
    isLoading: isLoadingMacroTopics,
    error: errorLoadingTopics,
  } = useNorthstar<CMSAPIDataCollection<CMSAPIDataItem<MacroTopicAttributes>>>(
    `/api/macro-topics`,
    accessToken,
    {
      method: "get",
    },
    {
      fields: ["topic", "order"],
      sort: { order: "asc" },
    },
  );
  const macroTopics =
    macroTopicsData?.data?.map((topic) => topic.attributes) || [];

  useEffect(() => {
    let ignore = true;
    if (
      (errorLoadingLatestMacroDocuments?.status === 401 ||
        errorLoadingRegions?.status === 401 ||
        errorLoadingTopics?.status === 401) &&
      !ignore &&
      clearIdentity
    ) {
      clearIdentity();
    }
    return () => {
      ignore = false;
    };
  }, [
    errorLoadingLatestMacroDocuments?.status,
    errorLoadingRegions?.status,
    errorLoadingTopics?.status,
  ]);

  let reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  if (
    isLoadingLatestMacroDocuments ||
    isLoadingInitial ||
    isLoadingMacroRegions
  ) {
    reportListTemplate = <LoadingStatus loadingType="reports_loading" />;
  } else if (
    errorLoadingLatestMacroDocuments ||
    errorLoadingRegions ||
    errorLoadingTopics
  ) {
    reportListTemplate = <LoadingStatus loadingType="reports_error" />;
  } else if (!macroDocuments?.length) {
    reportListTemplate = <LoadingStatus loadingType="reports_empty" />;
  } else if (
    selectedRegions.length === 0 &&
    selectedTopics.length === 0 &&
    latestMacroDocuments
  ) {
    reportListTemplate = (
      <div className="mt-3 space-y-4">
        {macroRegions.length > 0 &&
          macroRegions.map(({ region }) => (
            <div key={region}>
              <h3 className="text-lg text-bam-orange md:text-xl font-semibold uppercase -mb-2">
                {region}
              </h3>
              <ReportList reports={latestMacroDocuments[region]} isMacro />
            </div>
          ))}
      </div>
    );
  } else {
    reportListTemplate = (
      <ReportList
        reports={macroDocuments}
        isMacro
        hasMore={hasMore}
        loadMore={loadMoreDocuments}
        isLoadingMore={isLoadingMore}
      />
    );
  }

  return (
    <PageBoundary className="w-full">
      <MacroReportFilter
        macroRegions={macroRegions}
        selectedMacroRegions={selectedRegions}
        setSelectedMacroRegions={setSelectedRegionsWithReset}
        loadingMacroRegions={isLoadingMacroRegions}
        macroTopics={macroTopics}
        selectedMacroTopics={selectedTopics}
        setSelectedMacroTopics={setSelectedTopicsWithReset}
        loadingMacroTopics={isLoadingMacroTopics}
      />
      {reportListTemplate}
    </PageBoundary>
  );
};

export default MacroInsightsPage;
